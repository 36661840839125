import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { aboveIcons, belowIcons } from '../../utils/carSlideIcons';

// Reusable IconMarquee component
const IconMarquee = ({ icons, direction }) => {
  const [loadedIcons, setLoadedIcons] = useState([]);

  useEffect(() => {
    Promise.all(icons).then((resolvedIcons) => {
      setLoadedIcons(resolvedIcons);
    });
  }, [icons]);

  return (
    <Marquee
      speed={100}
      gradient={true}
      style={{ width: '100%', overflow: 'hidden', display: 'flex' }}
      delay={0}
      direction={direction}
      loop={0}
      autoFill={true}
      pauseOnClick={true}
    >
      {loadedIcons.map((icon, index) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            width: { xs: '84px', md: '180px' },
            height: { xs: '46px', md: '98px' },
            overflow: 'hidden',
            marginRight: { xs: '11px', md: 3 },
            marginLeft: { xs: '11px', md: 3 },
            boxSizing: 'border-box',
          }}
          key={index}
        >
          <Box
            sx={{
              width: { xs: '47px', md: '70px', lg: '100px' },
              height: { xs: '19px', md: '28px', lg: '40px' },
            }}
          >
            <img
              alt={`car-brand-${index + 1}`}
              src={icon.default}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </Box>
        </Box>
      ))}
    </Marquee>
  );
};

export const CarSlides = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '20px', md: '30px', lg: '40px' },
        padding: { xs: '24px 0', md: '48px 0', lg: '76px 0' },
        paddingTop: { xs: '70px', md: '132px', lg: '152px' },
      }}
    >
      <Typography
        sx={{
          color: '#021056',
          fontSize: { xs: '16px', md: '20px' },
          fontWeight: '600',
          lineHeight: { xs: '28px', md: '48px' },
          textAlign: 'center',
          margin: '0 auto',
          width: '100%',
        }}
      >
        Compatible with 40+ car makes
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: '8px', md: '18px' },
        }}
      >
        <IconMarquee icons={aboveIcons} direction="left" />
        <IconMarquee icons={belowIcons} direction="right" />
      </Box>
    </Box>
  );
};
