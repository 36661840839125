import React, { useEffect, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import ResponsiveLayout from "../../layout/ResponsiveLayout";
import { getToken } from "../../utils";
import { Hero } from "../../components/landing/Hero";
import { Header } from "../../components/landing/Header";
import { CarSlides } from "../../components/landing/CarSlides";

const StepSections = lazy(() =>
  import("../../components/landing/StepSections").then((module) => ({
    default: module.StepSections,
  }))
);
const FeedBackSections = lazy(() =>
  import("../../components/landing/FeedBackSections").then((module) => ({
    default: module.FeedBackSections,
  }))
);

const Footer = lazy(() =>
  import("../../components/landing/Footer").then((module) => ({
    default: module.Footer,
  }))
);
const FAQSection = lazy(() =>
  import("../../components/landing/FAQSection").then((module) => ({
    default: module.FAQSection,
  }))
);
const FeatureSections = lazy(() =>
  import("../../components/landing/FeatureSections").then((module) => ({
    default: module.FeatureSections,
  }))
);
const PlanSelection = lazy(() =>
  import("../../components/landing/PlanSelection").then((module) => ({
    default: module.PlanSelection,
  }))
);
const ConnectSection = lazy(() =>
  import("../../components/landing/ConnectSection").then((module) => ({
    default: module.ConnectSection,
  }))
);

export default function Landing() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  useEffect(() => {
    if (getToken()) {
      navigate("/cars");
    }
  }, [navigate]);

  return (
    <>
      <ResponsiveLayout isMobile={isMobile} isTablet={isTablet}>
        <Header />
      </ResponsiveLayout>

      <Hero />
      <CarSlides />

      <ResponsiveLayout isMobile={isMobile} isTablet={isTablet}>
        <FeatureSections />

        <Suspense fallback={<></>}>
          <StepSections />
        </Suspense>

        <Suspense fallback={<></>}>
          <PlanSelection />
        </Suspense>
      </ResponsiveLayout>

      <Box
        sx={{
          background:
            "linear-gradient(180deg, rgba(225, 235, 240, 0) 17.8%, #E0E6F3 97.96%)",
        }}
      >
        <ResponsiveLayout isMobile={isMobile} isTablet={isTablet}>
          <Suspense fallback={<></>}>
            <FeedBackSections />
          </Suspense>
        </ResponsiveLayout>
      </Box>

      <ResponsiveLayout isMobile={isMobile} isTablet={isTablet}>
        <Suspense fallback={<></>}>
          <FAQSection />
        </Suspense>

        <Suspense fallback={<></>}>
          <ConnectSection />
        </Suspense>

        <Suspense fallback={<></>}>
          <Footer />
        </Suspense>
      </ResponsiveLayout>
    </>
  );
}
