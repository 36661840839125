import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import heroImageMobile from "../../assets/images/hero-mobile.webp";
import heroBackground from "../../assets/images/hero-desktop.webp";
import heroCar from "../../assets/images/hero-car.webp";

export const Hero = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const heroBackgroundImage = isMobile ? heroImageMobile : heroBackground;

  return (
    <>
      <Box
        sx={{
          padding: { xs: "108px 0 106px 0", md: "144px 0 128px 0" },
          minHeight: { xs: "calc(100vh - 112px)", md: "100vh" },
          position: "relative",
          overflow: "hidden",
          display: "flex",
          alignItems: { xs: "flex-end", lg: "center" },
          background: `url(${heroBackgroundImage}) no-repeat center/cover`,
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: "100%", lg: "1152px" },
            margin: "0 auto",
            padding: { xs: "0 16px", md: "0 20px", lg: 0 },
            width: "100%",
            textAlign: { xs: "center", lg: "left" },
            marginBottom: isMobile ? "-6rem" : "0",
            zIndex: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "48px", md: "64px" },
              fontWeight: "600",
              lineHeight: { xs: "56px", md: "64px" },
              color: "#021056",
            }}
          >
            Digital Car Key
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              fontWeight: "500",
              lineHeight: { xs: "20px", md: "24px" },
              color: "#021056",
              width: { xs: "100%", lg: "50%" },
              marginTop: "12px",
            }}
          >
            Smartly connect and remote control your vehicles
          </Typography>
          <Button
            sx={{
              color: "#FFFFFF",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "16px",
              border: "none",
              backgroundColor: "#3772FF",
              width: isMobile ? "92%" : "18rem",
              height: "48px",
              marginTop: "3rem",
              "&:hover": {
                backgroundColor: "#0650CA",
              },
            }}
            onClick={() => navigate("/get-started")}
          >
            Connect your car
          </Button>
        </Box>

        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            right: 0,
            bottom: 0,
            overflow: "hidden",
            zIndex: 0,
          }}
        >
          {/* Car Image with Lazy Loading */}
          <Box
            component="img"
            src={heroCar}
            alt="Car"
            sx={{
              width: "42%",
              height: "auto",
              position: "absolute",
              right: 0,
              bottom: -40,
              display: { xs: "none", lg: "block" },
              zIndex: 2,
            }}
          />

          {/* Gradient Layer for the White Bottom Effect */}
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "30%",
              bottom: "0%",
              left: 0,
              background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 100%)`,
              zIndex: 3,
            }}
          />
        </Box>
      </Box>
    </>
  );
};
